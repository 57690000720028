import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleField"]
  static classes = ["hidden"]

  initialize() {
    this.element[this.identifier] = this
    super.initialize()
  }

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : "hidden"
    this.toggleFields()
  }

  toggleFields() {
    this.toggleFieldTargets.forEach((field) => {
      const toggleValues = JSON.parse(field.dataset.toggleValues)
      for (const inputName in toggleValues) {
        const { value, toggle_checkbox } = toggleValues[inputName]
        const currentValue = this.getInputValue(inputName, toggle_checkbox)

        const values = Array.isArray(value) ? value : [value]

        if (values.includes(currentValue)) {
          field.classList.remove("hidden")
          return
        } else {
          field.classList.add("hidden")
        }
      }
    })
  }

  getInputValue(fieldName, toggleCheckbox) {
    const selector = toggleCheckbox
      ? `[name="${fieldName}"]:checked`
      : `[name="${fieldName}"]`
    const input = this.element.querySelector(selector)
    return input ? input.value : ""
  }

  toggle(_event) {
    this.toggleFields()
  }
}
